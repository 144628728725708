import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"

const MovilidadSosteniblePage = () => {
  const { strapiSustainableMobility } = useStaticQuery(graphql`
    query {
      strapiSustainableMobility {
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            id
            url
            localFile {
              url
            }
          }
        }
        blocks {
          __typename
          ... on STRAPI__COMPONENT_SHARED_HEADER {
            title
            cols
            isLarge
            cover {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
            richTextBody: body {
              __typename
              data {
                id
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_MEDIA {
            id
            file {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ...BlockImageSupport
          ...BaseSectionTitle
        }
      }
    }
  `)

  const { defaultSeo, blocks } = strapiSustainableMobility

  return (
    <Layout>
      <Seo seo={{ ...defaultSeo, url: "/movilidad-sostenible" }} />
      <BaseBlocksRenderer blocks={blocks} />
    </Layout>
  )
}

export default MovilidadSosteniblePage
